import * as React from "react"

import "./Layout.scss"
import { Menu } from "./Menu/Menu"
import Content from "./Content"

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Menu />
      <Content>
          {children}
      </Content>
    </div>
  )
}

export default Layout
