import * as React from "react"

import "./Content.scss"

const Content: React.FC = ({ children }) => {
  return (
      <main className="main">
        <div className="max-width">
          {children}
        </div>
      </main>
  )
}

export default Content
