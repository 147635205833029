import * as React from "react"
import { Link } from "gatsby"
import "./Menu.scss"
import { StaticImage } from "gatsby-plugin-image"
import { useState } from "react"

export const Menu = ({}) => {
  const [opened, setOpened] = useState(false)

  return (
    <div className="menu">
      <StaticImage
        className="image"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../../images/menu.jpg"
        height={1080}
        quality={95}
        alt="Profile picture"
      />
      <div className="inner">
        <div className="brand"><small>Chovatelská stanice</small><br /><b>Ze Mlýna Svaté Anny</b></div>

        <div className={`menu-toggle ${opened && "opened"}`} onClick={() => setOpened(opened => !opened)} />

        <div className={`menu-items ${opened && "opened"}`}>
          <Link className="menu-item" to="/" itemProp="url">Domů</Link>
          <Link className="menu-item" to="/bsp" itemProp="url">BSP</Link>
          <Link className="menu-item" to="/o-nas">O nás</Link>
          <Link className="menu-item" to="/nasi-psi">Naši psi</Link>
          <Link className="menu-item" to="/kontakty">Kontakty</Link>
        </div>

        <a href="https://www.facebook.com/Ze-mlýna-Svaté-Anny-108586008208432" target="_blank" className="facebook">
          <StaticImage className="logo" width={32} height={32} src="../../images/facebook.svg" alt="Facebook" />
        </a>
      </div>
    </div>
  )
}